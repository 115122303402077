<template>
  <div v-loading.fullscreen.lock="loading" element-loading-text="考卷產製中...">
    <control v-if="loaded"></control>
    <!-- module quest 發送  執行list slot 的update -->
    <List v-slot="slot" class="main">
      <Quest
        :id="'quest-' + slot.row.no"
        :row="slot.row"
        :result="slot.result"
      />
    </List>
  </div>
</template>
<script>
import { provide, onMounted, computed, nextTick } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import MessageBox from '@/components/messageBox/Index';
import Quest from '@/modules/quest/Normal.vue';

import Control from '@/modules/tester/control/Index.vue';
import List from '@/modules/tester/List';
import useTester from '@/modules/tester/useTester';
// import useAnswer from '@/modules/tester/useAnswer';
import useCounter from '@/modules/tester/control//useCounter';
export default {
  components: { List, Control, Quest },
  setup() {
    const testerData = useTester({
      defaultFrom: {},
    });
    provide('testerData', testerData);

    const { saveds, updateSaved, updateConf, updateAns } = testerData;
    provide('actions', { saveds, updateSaved, updateConf, updateAns });

    const counter = useCounter({
      toggleFreezing: testerData.toggleFreezing,
      restart: testerData.restart,
      countdown: testerData.ansTime,
    });
    provide('counter', counter);

    onMounted(() => {
      testerData.fetcher({ empty: true });
    });

    const loaded = computed(() => {
      return testerData.rowsData && testerData.rowsData.rows.length
        ? true
        : false;
    });

    const loading = computed(() => {
      return testerData.loading.value;
    });

    onBeforeRouteLeave((to, from, next) => {
      nextTick(() => {
        if (testerData.submitted.value || testerData.unpermission.value) {
          next();
        } else {
          counter.toggle();
          MessageBox.confirm(
            '此動作會放棄之前的作答並重新計時',
            '離開頁面前記得儲存',
            'alert',
            { confirmButtonText: '了解並離開' },
          )
            .then(() => {
              counter.toggle();
              next();
            })
            .catch((err) => {
              counter.toggle();
              console.log(err);
            });
        }
      });
    });

    return { loaded, loading };
  },
};
</script>
