<template>
  <Layout :row="row">
    <template #answerBlock>
      <div v-if="row.qType.id <= 3">
        <Opt
          v-for="option in row.option"
          :key="option.id"
          :opt="option"
          :selected="selected(option)"
          @update:ans="(_opt) => handleUpdateAns(_opt)"
        />
      </div>
      <div v-else>
        <Texter
          :model-value="result"
          @update:modelValue="(content) => handleUpdateAns(content)"
        />
      </div>
    </template>
    <template #infoBlock>
      <div class="bg-gray-50 p-4 flex justify-between items-center">
        <div class="text-gray-300">#{{ row.id }}</div>
        <div class="flex items-center">
          <Conf
            :id="row.id"
            :model-value="row.conf"
            @update:modelValue="handleUpdateConf"
          ></Conf>
          <el-tooltip
            effect="dark"
            :content="isSaved ? '取消加入收藏題庫' : '加入收藏題庫'"
            placement="top-start"
          >
            <div
              class="ml-4 cursor-pointer"
              @click="handleUpdateSaved(!isSaved)"
            >
              <font-awesome-icon
                :icon="isSaved ? ['fa', 'bookmark'] : ['far', 'bookmark']"
                class="text-3xl text-gray duration-300"
                :class="{ 'text-yellow': isSaved }"
              />
            </div>
          </el-tooltip>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import { toRefs, inject, computed } from 'vue';
import Opt from './ans/Opt.vue';
import Texter from './ans/Texter.vue';
import { ElTooltip } from 'element-plus';
import Conf from '@/modules/quest/conf/Index';
import Layout from './_Layout.vue';
export default {
  components: { Opt, Conf, Texter, Layout, ElTooltip },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    result: {
      type: [Array, String],
      default: null,
    },
  },

  setup(props) {
    const { row, result } = toRefs(props);

    const { saveds, updateSaved, updateConf, updateAns } = inject('actions');

    const isSaved = computed(() => {
      return saveds.indexOf(row.value.id) > -1;
    });
    // 這樣會部會很吃效能呢????
    const selected = (opt) => {
      return !!(
        result.value &&
        result.value !== 0 &&
        result.value.length &&
        result.value.indexOf(opt.id) > -1
      );
    };

    const handleUpdateAns = (ans) => {
      updateAns({
        ans,
        id: row.value.id,
        qType: row.value.qType,
        no: row.value.no,
      });
    };

    const handleUpdateConf = (confId) => {
      updateConf({ id: row.value.id, conf: confId });
    };

    const handleUpdateSaved = (saved = true) => {
      updateSaved({ id: row.value.id, saved });
    };

    return {
      selected,
      handleUpdateAns,
      handleUpdateConf,
      handleUpdateSaved,
      isSaved,
    };
  },
};
</script>
