import { ref, onUnmounted, onMounted, computed, inject } from 'vue';
import { formatSec } from '@/utils';
import MessageBox from '@/components/messageBox/Index';
export default ({ toggleFreezing, restart, countdown }) => {
  const isPlaying = ref(false);

  let counter;

  const destroy = () => {
    clearInterval(counter);
    counter = null;
  };

  const play = () => {
    if (counter) {
      destroy();
    }
    counter = setInterval(() => {
      countdown.value += 1;
    }, 1000);
    toggleFreezing(false);
  };

  const pause = () => {
    if (counter) {
      destroy();
      toggleFreezing(true);
    }
  };
  const toggle = () => {
    isPlaying.value = !isPlaying.value;
    if (isPlaying.value) {
      play();
    } else {
      pause();
    }
  };
  const sec = computed(() => {
    return formatSec(countdown.value);
  });
  const reset = async () => {
    toggle();
    try {
      const confirm = await MessageBox.confirm(
        '確定重來?',
        '此動作會放棄之前的作答並重新計時',
        '',
        { confirmButtonText: '了解並離開' },
      );
      if (confirm === 'confirm') {
        //   還要執行rows 的reset

        countdown.value = 0;
        restart();
      }
    } catch (error) {
      console.log(error);
    }
    toggle();
  };

  onMounted(() => {
    toggle();
  });

  onUnmounted(() => {
    destroy();
  });

  return {
    reset,
    sec,
    isPlaying,
    toggle,
  };
};
