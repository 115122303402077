import { ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Tester from '@/api/Tester';
import _ from 'lodash';
import { ElMessage } from 'element-plus';
import MessageBox from '@/components/messageBox/Index';
import useRows from './useRows';

import useAnswer from './useAnswer';

export default () => {
  const route = useRoute();
  const router = useRouter();
  const testId = ref(route.params.testId);

  const {
    form,
    rowsData,
    dataType,
    fetcher: _fetcher,
    updateMode,
    updateConf,
    saveds,
    updateSaved,
    more,
    next,
    prev,
    goto,
    first,
    loading,
  } = useRows({
    API: Tester.list,
    testId: testId.value,
    defaultFrom: {},
  });

  const answerData = useAnswer({ type: 'test', testId: testId.value });

  const permission = inject('permission');
  const ansTime = ref(0);

  const unpermission = ref(false);
  const fetcher = async () => {
    loading.value = true;

    await answerData.fetcher();
    const res = await _fetcher();
    if (
      !(await permission.dialog(
        res.status == 402 ? '0' : '1',
        '1.沒有啟用任何方案。 \n 2.選擇的內容不包括在啟用方案中。',
      ))
    ) {
      unpermission.value = true;
      return;
    }

    if (res.code === 1) {
      ansTime.value = res.data.ansTime;
      dataType.push(...res.data.subject, ...res.data.qType);
    }
    loading.value = false;
  };

  const reset = () => {
    for (var key in answerData.form) {
      answerData.form[key] = [];
    }
  };

  const restart = () => {
    reset();
    first();
  };

  const submitted = ref(false);
  const submit = (type = 'save') => {
    return MessageBox.confirm('確定交卷嗎?', '馬上看考試結果與解析', 'success')
      .then(async () => {
        loading.value = true;

        const res = await Tester.save({
          testId: testId.value,
          type: 'submit',
          ansTime: ansTime.value,
          ansIds: answerData.getAnsIds(),
        });

        if (res.code === 1) {
          submitted.value = true;
          router.push({
            name: 'Tester.Result',
            params: { testId: testId.value },
          });
        }

        loading.value = false;

        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const preSubmit = () => {
    loading.value = true;

    return Tester.save({
      testId: testId.value,
      type: 'save',
      ansTime: ansTime.value,
      ansIds: answerData.getAnsIds(),
    })
      .then((res) => {
        if (res.code === 1) {
          ElMessage({ message: '成功存檔', type: 'success' });
        }

        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const isFreezing = ref(false);

  const toggleFreezing = (toggle) => {
    isFreezing.value = toggle;
  };

  return {
    form,
    testId,
    rowsData,
    dataType,
    fetcher,
    more,
    reset,
    updateAns: answerData.update,
    answerResult: answerData.form,
    answered: answerData.answered,
    updateMode,
    updateConf,
    saveds,
    updateSaved,
    submitted,
    unpermission,
    next,
    prev,
    goto,
    loading,
    preSubmit,
    submit,
    ansTime,
    isFreezing,
    restart,
    toggleFreezing,
  };
};
