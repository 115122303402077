<template>
  <div class="flex items-center space-x-6">
    <El-Button circle class="text-primary" @click="toggle">
      <font-awesome-icon
        :icon="!isPlaying ? ['fas', 'play'] : ['fas', 'pause']"
        class="relative"
        :class="{ 'left-[2px]': !isPlaying }"
      />
    </El-Button>

    <span class="text-xl text-gray-600">
      {{ sec }}
    </span>

    <div>
      <El-Button
        type="text"
        class="underline hover:text-danger -ml-2"
        @click="reset"
      >
        重來
      </El-Button>
    </div>
  </div>
</template>
<script>
import { inject } from 'vue';

export default {
  setup() {
    const { reset, isPlaying, toggle, sec } = inject('counter');

    return {
      reset,
      sec,
      isPlaying,
      toggle,
    };
  },
};
</script>
