<template>
  <div
    ref="draggable"
    class="bg-white border rounded-lg shadow flex items-center p-4 z-30 fixed hover:shadow-2xl transition-shadow md:justify-center controller"
    :style="styleObject"
    :class="{ 'md:translate-y-full': hideOnMoblie }"
  >
    <div
      class="hidden md:block absolute bottom-full left-1/2 text-center w-1/4 rounded-t-lg bg-[#F5F8FA] text-gray border -translate-x-1/2"
      @click="hideOnMoblie = !hideOnMoblie"
    >
      <font-awesome-icon :icon="['fas', 'angle-down']" />
    </div>
    <div>
      <font-awesome-icon
        :icon="['fas', 'grip-lines-vertical']"
        class="text-gray opacity-40 text-2xl cursor-move md:hidden"
      />
    </div>
    <div class="flex ml-4 md:flex-col space-x-2 md:space-x-0">
      <div class="flex space-x-4">
        <Counter />
        <Summery />
      </div>
      <div class="flex md:mt-4 space-x-4 items-center">
        <Mode />
        <el-popover
          trigger="click"
          placement="top"
          :width="200"
          content="欲保留測驗作答內容，必須先儲存，方可離開後繼續作答此測驗"
        >
          <template #reference>
            <div class="cursor-pointer text-lg text-gray">
              <font-awesome-icon
                :icon="['fas', 'exclamation-circle']"
                class="block"
              />
            </div>
          </template>
        </el-popover>
        <el-button type="primary" @click="preSubmit_">存檔</el-button>
        <el-button type="primary" @click="submit_">交卷</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElPopover } from 'element-plus';

import { inject, computed, ref, provide } from 'vue';
import Counter from './_Counter.vue';
import Summery from './_Summery.vue';
import Mode from './_Mode.vue';
import useDrag from './useDrag';
// import useCounter from './useCounter';
export default {
  components: { Counter, Summery, Mode, ElPopover },
  setup() {
    const { submit, preSubmit, testId } = inject('testerData');
    const { restrictedY, restrictedX, draggable } = useDrag();
    const { update } = inject('testerRecord');
    const counter = inject('counter');

    const submit_ = async () => {
      counter.toggle();
      const res = await submit();
      update(null);
      if (!res) {
        counter.toggle();
      }
    };

    const preSubmit_ = async () => {
      counter.toggle();
      const res = await preSubmit();
      if (res.code === 1) {
        update(testId.value);
        counter.toggle();
      }
    };
    const hideOnMoblie = ref(false);

    const styleObject = computed(() => {
      return {
        userSelect: 'none',
        position: 'fixed',
        top: `${restrictedY.value}px`,
        left: `${restrictedX.value}px`,
      };
    });

    return {
      draggable,
      restrictedX,
      restrictedY,
      submit_,
      preSubmit_,
      styleObject,
      hideOnMoblie,
    };
  },
};
</script>

<style lang="sass" scoped>
.controller
  @screen md
    bottom: 0!important
    top: auto!important
    left: 0!important
    width: 100%
    height: 9rem
</style>
