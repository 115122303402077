<template>
  <div class="flex items-center p-4 text-xl cursor-pointer">
    <el-input
      v-model="model"
      :rows="3"
      type="textarea"
      placeholder="請在此作答"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { computed } from 'vue';
import { ElInput } from 'element-plus';
// import { useVModel } from '@vueuse/core';

export default {
  components: { ElInput },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue ? props.modelValue[0] : [];
      },
      set(val) {
        emit('update:modelValue', [val]);
      },
    });
    // const model = useVModel(props, 'modelValue', emit);
    return { model };
  },
};
</script>
