<template>
  <Layout
    :cls="[selected ? 'selected' : '']"
    :opt="opt"
    class="cursor-pointer"
    @click="update(opt)"
  ></Layout>
</template>

<script>
import _ from 'lodash';
import Layout from './_OptLayout.vue';
export default {
  components: { Layout },
  props: {
    opt: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:ans'],
  setup(props, { emit }) {
    const update = (_opt) => {
      emit('update:ans', _opt);
    };

    return { update };
  },
};
</script>
